<template>
  <div id="roulette"></div>
</template>

<script>
import DataService from "../services/data.service";

export default {
  created() {
    if (!DataService.checkLoggedIn()) {
      this.$router.push("/Login");
    }
  },
};
let user = DataService.getLoggedInUser();
let bankValue = 0;
DataService.getBalanceOfUser(user.id).then((response) => {
  bankValue = response;
  document.getElementById("bankSpan").innerText = bankValue;
});
let currentBet = 0;
let wager = 5;
let lastWager = 0;
let bet = [];
let numbersBet = [];
let previousNumbers = [];
let response = [];

let numRed = [
  1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
];
let wheelnumbersAC = [
  0, 26, 3, 35, 12, 28, 7, 29, 18, 22, 9, 31, 14, 20, 1, 33, 16, 24, 5, 10, 23,
  8, 30, 11, 36, 13, 27, 6, 34, 17, 25, 2, 21, 4, 19, 15, 32,
];

let container = document.createElement("div");
container.setAttribute("id", "containerDiv");
document.body.append(container);

startGame();

let wheel = document.getElementsByClassName("wheel")[0];
let ballTrack = document.getElementsByClassName("ballTrack")[0];

function resetGame() {
  currentBet = 0;
  wager = 5;
  bet = [];
  numbersBet = [];
  previousNumbers = [];
  document.getElementById("betting_board").remove();
  document.getElementById("notification").remove();
  buildBettingBoard();
}

function startGame() {
  buildWheel();
  buildBettingBoard();
}

function gameOver() {
  let notification = document.createElement("div");
  notification.setAttribute("id", "notification");
  let nSpan = document.createElement("span");
  nSpan.setAttribute("class", "nSpan");
  nSpan.innerText = "Bankrupt";
  notification.append(nSpan);

  let nBtn = document.createElement("div");
  nBtn.setAttribute("class", "nBtn");
  nBtn.innerText = "Play again";
  nBtn.onclick = function () {
    resetGame();
  };
  notification.append(nBtn);
  container.prepend(notification);
}

function buildWheel() {
  let wheel = document.createElement("div");
  wheel.setAttribute("class", "wheel");

  let outerRim = document.createElement("div");
  outerRim.setAttribute("class", "outerRim");
  wheel.append(outerRim);

  let numbers = [
    0, 32, 15, 19, 4, 21, 2, 25, 17, 34, 6, 27, 13, 36, 11, 30, 8, 23, 10, 5,
    24, 16, 33, 1, 20, 14, 31, 9, 22, 18, 29, 7, 28, 12, 35, 3, 26,
  ];
  for (let i = 0; i < numbers.length; i++) {
    let a = i + 1;
    let spanClass = numbers[i] < 10 ? "single" : "double";
    let sect = document.createElement("div");
    sect.setAttribute("id", "sect" + a);
    sect.setAttribute("class", "sect");
    let span = document.createElement("span");
    span.setAttribute("class", spanClass);
    span.innerText = numbers[i];
    sect.append(span);
    let block = document.createElement("div");
    block.setAttribute("class", "block");
    sect.append(block);
    wheel.append(sect);
  }

  let pocketsRim = document.createElement("div");
  pocketsRim.setAttribute("class", "pocketsRim");
  wheel.append(pocketsRim);

  let ballTrack = document.createElement("div");
  ballTrack.setAttribute("class", "ballTrack");
  let ball = document.createElement("div");
  ball.setAttribute("class", "ball");
  ballTrack.append(ball);
  wheel.append(ballTrack);

  let pockets = document.createElement("div");
  pockets.setAttribute("class", "pockets");
  wheel.append(pockets);

  let cone = document.createElement("div");
  cone.setAttribute("class", "cone");
  wheel.append(cone);

  let turret = document.createElement("div");
  turret.setAttribute("class", "turret");
  wheel.append(turret);

  let turretHandle = document.createElement("div");
  turretHandle.setAttribute("class", "turretHandle");
  let thendOne = document.createElement("div");
  thendOne.setAttribute("class", "thendOne");
  turretHandle.append(thendOne);
  let thendTwo = document.createElement("div");
  thendTwo.setAttribute("class", "thendTwo");
  turretHandle.append(thendTwo);
  wheel.append(turretHandle);

  container.append(wheel);
}

function buildBettingBoard() {
  let bettingBoard = document.createElement("div");
  bettingBoard.setAttribute("id", "betting_board");

  let wl = document.createElement("div");
  wl.setAttribute("class", "winning_lines");

  var wlttb = document.createElement("div");
  wlttb.setAttribute("id", "wlttb_top");
  wlttb.setAttribute("class", "wlttb");
  for (let i = 0; i < 11; i++) {
    let j = i;
    var ttbbetblock = document.createElement("div");
    ttbbetblock.setAttribute("class", "ttbbetblock");
    var numA = 1 + 3 * j;
    var numB = 2 + 3 * j;
    var numC = 3 + 3 * j;
    var numD = 4 + 3 * j;
    var numE = 5 + 3 * j;
    var numF = 6 + 3 * j;
    let num =
      numA +
      ", " +
      numB +
      ", " +
      numC +
      ", " +
      numD +
      ", " +
      numE +
      ", " +
      numF;
    var objType = "double_street";
    ttbbetblock.onclick = function () {
      setBet(this, num, objType, 5);
    };
    ttbbetblock.oncontextmenu = function (e) {
      e.preventDefault();
      removeBet(this, num, objType, 5);
    };
    wlttb.append(ttbbetblock);
  }
  wl.append(wlttb);

  for (let c = 1; c < 4; c++) {
    let d = c;
    var wlttb = document.createElement("div");
    wlttb.setAttribute("id", "wlttb_" + c);
    wlttb.setAttribute("class", "wlttb");
    for (let i = 0; i < 12; i++) {
      let j = i;
      var ttbbetblock = document.createElement("div");
      ttbbetblock.setAttribute("class", "ttbbetblock");
      ttbbetblock.onclick = function () {
        if (d == 1 || d == 2) {
          var numA = 2 - (d - 1) + 3 * j;
          var numB = 3 - (d - 1) + 3 * j;
          var num = numA + ", " + numB;
        } else {
          var numA = 1 + 3 * j;
          var numB = 2 + 3 * j;
          var numC = 3 + 3 * j;
          var num = numA + ", " + numB + ", " + numC;
        }
        var objType = d == 3 ? "street" : "split";
        var odd = d == 3 ? 11 : 17;
        setBet(this, num, objType, odd);
      };
      ttbbetblock.oncontextmenu = function (e) {
        e.preventDefault();
        if (d == 1 || d == 2) {
          var numA = 2 - (d - 1) + 3 * j;
          var numB = 3 - (d - 1) + 3 * j;
          var num = numA + ", " + numB;
        } else {
          var numA = 1 + 3 * j;
          var numB = 2 + 3 * j;
          var numC = 3 + 3 * j;
          var num = numA + ", " + numB + ", " + numC;
        }
        var objType = d == 3 ? "street" : "split";
        var odd = d == 3 ? 11 : 17;
        removeBet(this, num, objType, odd);
      };
      wlttb.append(ttbbetblock);
    }
    wl.append(wlttb);
  }

  for (let c = 1; c < 12; c++) {
    let d = c;
    var wlrtl = document.createElement("div");
    wlrtl.setAttribute("id", "wlrtl_" + c);
    wlrtl.setAttribute("class", "wlrtl");
    for (let i = 1; i < 4; i++) {
      let j = i;
      var rtlbb = document.createElement("div");
      rtlbb.setAttribute("class", "rtlbb" + i);
      var numA = 3 + 3 * (d - 1) - (j - 1);
      var numB = 6 + 3 * (d - 1) - (j - 1);
      let num = numA + ", " + numB;
      rtlbb.onclick = function () {
        setBet(this, num, "split", 17);
      };
      rtlbb.oncontextmenu = function (e) {
        e.preventDefault();
        removeBet(this, num, "split", 17);
      };
      wlrtl.append(rtlbb);
    }
    wl.append(wlrtl);
  }

  for (let c = 1; c < 3; c++) {
    var wlcb = document.createElement("div");
    wlcb.setAttribute("id", "wlcb_" + c);
    wlcb.setAttribute("class", "wlcb");
    for (let i = 1; i < 12; i++) {
      let count = c == 1 ? i : i + 11;
      var cbbb = document.createElement("div");
      cbbb.setAttribute("id", "cbbb_" + count);
      cbbb.setAttribute("class", "cbbb");
      var numA = "2";
      var numB = "3";
      var numC = "5";
      var numD = "6";
      let num =
        count >= 1 && count < 12
          ? parseInt(numA) +
            (count - 1) * 3 +
            ", " +
            (parseInt(numB) + (count - 1) * 3) +
            ", " +
            (parseInt(numC) + (count - 1) * 3) +
            ", " +
            (parseInt(numD) + (count - 1) * 3)
          : parseInt(numA) -
            1 +
            (count - 12) * 3 +
            ", " +
            (parseInt(numB) - 1 + (count - 12) * 3) +
            ", " +
            (parseInt(numC) - 1 + (count - 12) * 3) +
            ", " +
            (parseInt(numD) - 1 + (count - 12) * 3);
      var objType = "corner_bet";
      cbbb.onclick = function () {
        setBet(this, num, objType, 8);
      };
      cbbb.oncontextmenu = function (e) {
        e.preventDefault();
        removeBet(this, num, objType, 8);
      };
      wlcb.append(cbbb);
    }
    wl.append(wlcb);
  }

  bettingBoard.append(wl);

  let bbtop = document.createElement("div");
  bbtop.setAttribute("class", "bbtop");
  let bbtopBlocks = ["1 to 18", "19 to 36"];
  for (let i = 0; i < bbtopBlocks.length; i++) {
    let f = i;
    var bbtoptwo = document.createElement("div");
    bbtoptwo.setAttribute("class", "bbtoptwo");
    let num =
      f == 0
        ? "1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18"
        : "19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36";
    var objType = f == 0 ? "outside_low" : "outside_high";
    bbtoptwo.onclick = function () {
      setBet(this, num, objType, 1);
    };
    bbtoptwo.oncontextmenu = function (e) {
      e.preventDefault();
      removeBet(this, num, objType, 1);
    };
    bbtoptwo.innerText = bbtopBlocks[i];
    bbtop.append(bbtoptwo);
  }
  bettingBoard.append(bbtop);

  let numberBoard = document.createElement("div");
  numberBoard.setAttribute("class", "number_board");

  let zero = document.createElement("div");
  zero.setAttribute("class", "number_0");
  var objType = "zero";
  var odds = 35;
  zero.onclick = function () {
    setBet(this, "0", objType, odds);
  };
  zero.oncontextmenu = function (e) {
    e.preventDefault();
    removeBet(this, "0", objType, odds);
  };
  let nbnz = document.createElement("div");
  nbnz.setAttribute("class", "nbn");
  nbnz.innerText = "0";
  zero.append(nbnz);
  numberBoard.append(zero);

  var numberBlocks = [
    3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 2, 5, 8, 11, 14, 17, 20, 23,
    26, 29, 32, 35, 1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34,
  ];
  var redBlocks = [
    1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36,
  ];
  for (let i = 0; i < numberBlocks.length; i++) {
    let a = i;
    var nbClass = numberBlocks[i] == "2 to 1" ? "tt1_block" : "number_block";
    var colourClass = redBlocks.includes(numberBlocks[i])
      ? " redNum"
      : nbClass == "number_block"
      ? " blackNum"
      : "";
    var numberBlock = document.createElement("div");
    numberBlock.setAttribute("class", nbClass + colourClass);
    numberBlock.onclick = function () {
      if (numberBlocks[a] != "2 to 1") {
        setBet(this, "" + numberBlocks[a] + "", "inside_whole", 35);
      } else {
        let num =
          a == 12
            ? "3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36"
            : a == 25
            ? "2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35"
            : "1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34";
        setBet(this, num, "outside_column", 2);
      }
    };
    numberBlock.oncontextmenu = function (e) {
      e.preventDefault();
      if (numberBlocks[a] != "2 to 1") {
        removeBet(this, "" + numberBlocks[a] + "", "inside_whole", 35);
      } else {
        let num =
          a == 12
            ? "3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36"
            : a == 25
            ? "2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35"
            : "1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34";
        removeBet(this, num, "outside_column", 2);
      }
    };
    var nbn = document.createElement("div");
    nbn.setAttribute("class", "nbn");
    nbn.innerText = numberBlocks[i];
    numberBlock.append(nbn);
    numberBoard.append(numberBlock);
  }
  bettingBoard.append(numberBoard);

  let bo3Board = document.createElement("div");
  bo3Board.setAttribute("class", "bo3_board");
  let bo3Blocks = ["1 to 12", "13 to 24", "25 to 36"];
  for (let i = 0; i < bo3Blocks.length; i++) {
    let b = i;
    var bo3Block = document.createElement("div");
    bo3Block.setAttribute("class", "bo3_block");
    bo3Block.onclick = function () {
      let num =
        b == 0
          ? "1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12"
          : b == 1
          ? "13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24"
          : "25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36";
      setBet(this, num, "outside_dozen", 2);
    };
    bo3Block.oncontextmenu = function (e) {
      e.preventDefault();
      let num =
        b == 0
          ? "1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12"
          : b == 1
          ? "13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24"
          : "25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36";
      removeBet(this, num, "outside_dozen", 2);
    };
    bo3Block.innerText = bo3Blocks[i];
    bo3Board.append(bo3Block);
  }
  bettingBoard.append(bo3Board);

  let otoBoard = document.createElement("div");
  otoBoard.setAttribute("class", "oto_board");
  let otoBlocks = ["EVEN", "RED", "BLACK", "ODD"];
  for (let i = 0; i < otoBlocks.length; i++) {
    let d = i;
    var colourClass =
      otoBlocks[i] == "RED"
        ? " redNum"
        : otoBlocks[i] == "BLACK"
        ? " blackNum"
        : "";
    var otoBlock = document.createElement("div");
    otoBlock.setAttribute("class", "oto_block" + colourClass);
    otoBlock.onclick = function () {
      let num =
        d == 0
          ? "2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36"
          : d == 1
          ? "1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36"
          : d == 2
          ? "2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35"
          : "1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35";
      setBet(this, num, "outside_oerb", 1);
    };
    otoBlock.oncontextmenu = function (e) {
      let num =
        d == 0
          ? "2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 30, 32, 34, 36"
          : d == 1
          ? "1, 3, 5, 7, 9, 12, 14, 16, 18, 19, 21, 23, 25, 27, 30, 32, 34, 36"
          : d == 2
          ? "2, 4, 6, 8, 10, 11, 13, 15, 17, 20, 22, 24, 26, 28, 29, 31, 33, 35"
          : "1, 3, 5, 7, 9, 11, 13, 15, 17, 19, 21, 23, 25, 27, 29, 31, 33, 35";
      e.preventDefault();
      removeBet(this, num, "outside_oerb", 1);
    };
    otoBlock.innerText = otoBlocks[i];
    otoBoard.append(otoBlock);
  }
  bettingBoard.append(otoBoard);

  let chipDeck = document.createElement("div");
  chipDeck.setAttribute("class", "chipDeck");
  let chipValues = [1, 5, 10, 100, "clear"];
  for (let i = 0; i < chipValues.length; i++) {
    let cvi = i;
    let chipColour =
      i == 0
        ? "red"
        : i == 1
        ? "blue cdChipActive"
        : i == 2
        ? "orange"
        : i == 3
        ? "gold"
        : "clearBet";
    let chip = document.createElement("div");
    chip.setAttribute("class", "cdChip " + chipColour);
    chip.onclick = function () {
      if (cvi !== 4) {
        let cdChipActive = document.getElementsByClassName("cdChipActive");
        for (i = 0; i < cdChipActive.length; i++) {
          cdChipActive[i].classList.remove("cdChipActive");
        }
        let curClass = this.getAttribute("class");
        if (!curClass.includes("cdChipActive")) {
          this.setAttribute("class", curClass + " cdChipActive");
        }
        wager = parseInt(chip.childNodes[0].innerText);
      } else {
        bankValue = bankValue + currentBet;
        currentBet = 0;
        document.getElementById("bankSpan").innerText =
          "" + bankValue.toLocaleString("en-GB") + "";
        document.getElementById("betSpan").innerText =
          "" + currentBet.toLocaleString("en-GB") + "";
        clearBet();
        removeChips();
      }
    };
    let chipSpan = document.createElement("span");
    chipSpan.setAttribute("class", "cdChipSpan");
    chipSpan.innerText = chipValues[i];
    chip.append(chipSpan);
    chipDeck.append(chip);
  }
  bettingBoard.append(chipDeck);

  let bankContainer = document.createElement("div");
  bankContainer.setAttribute("class", "bankContainer");

  let bank = document.createElement("div");
  bank.setAttribute("class", "bank");
  let bankSpan = document.createElement("span");
  bankSpan.setAttribute("id", "bankSpan");
  bankSpan.innerText = "" + bankValue.toLocaleString("en-GB") + "";
  bank.append(bankSpan);
  bankContainer.append(bank);

  let bet = document.createElement("div");
  bet.setAttribute("class", "bet");
  let betSpan = document.createElement("span");
  betSpan.setAttribute("id", "betSpan");
  betSpan.innerText = "" + currentBet.toLocaleString("en-GB") + "";
  bet.append(betSpan);
  bankContainer.append(bet);
  bettingBoard.append(bankContainer);

  let pnBlock = document.createElement("div");
  pnBlock.setAttribute("class", "pnBlock");
  let pnContent = document.createElement("div");
  pnContent.setAttribute("id", "pnContent");
  pnContent.onwheel = function (e) {
    e.preventDefault();
    pnContent.scrollLeft += e.deltaY;
  };
  pnBlock.append(pnContent);
  bettingBoard.append(pnBlock);

  container.append(bettingBoard);
}

function clearBet() {
  bet = [];
  numbersBet = [];
}

function setBet(e, n, t, o) {
  lastWager = wager;
  wager = bankValue < wager ? bankValue : wager;
  if (wager > 0) {
    if (!container.querySelector(".spinBtn")) {
      let spinBtn = document.createElement("div");
      spinBtn.setAttribute("class", "spinBtn");
      spinBtn.innerText = "spin";
      spinBtn.onclick = function () {
        this.remove();
        spin();
      };
      container.append(spinBtn);
    }
    bankValue = bankValue - wager;
    currentBet = currentBet + wager;
    document.getElementById("bankSpan").innerText =
      "" + bankValue.toLocaleString("en-GB") + "";
    document.getElementById("betSpan").innerText =
      "" + currentBet.toLocaleString("en-GB") + "";
    for (let i = 0; i < bet.length; i++) {
      if (bet[i].numbers == n && bet[i].type == t) {
        bet[i].amt = bet[i].amt + wager;
        let chipColour =
          bet[i].amt < 5
            ? "red"
            : bet[i].amt < 10
            ? "blue"
            : bet[i].amt < 100
            ? "orange"
            : "gold";
        e.querySelector(".chip").style.cssText = "";
        e.querySelector(".chip").setAttribute("class", "chip " + chipColour);
        let chipSpan = e.querySelector(".chipSpan");
        chipSpan.innerText = bet[i].amt;
        return;
      }
    }
    var obj = {
      amt: wager,
      type: t,
      odds: o,
      numbers: n,
    };
    bet.push(obj);

    let numArray = n.split(",").map(Number);
    for (let i = 0; i < numArray.length; i++) {
      if (!numbersBet.includes(numArray[i])) {
        numbersBet.push(numArray[i]);
      }
    }

    if (!e.querySelector(".chip")) {
      let chipColour =
        wager < 5
          ? "red"
          : wager < 10
          ? "blue"
          : wager < 100
          ? "orange"
          : "gold";
      let chip = document.createElement("div");
      chip.setAttribute("class", "chip " + chipColour);
      let chipSpan = document.createElement("span");
      chipSpan.setAttribute("class", "chipSpan");
      chipSpan.innerText = wager;
      chip.append(chipSpan);
      e.append(chip);
    }
  }
}

async function getWinningSpin(bet) {
  let stringBets = btoa(JSON.stringify(bet));
  let user = DataService.getLoggedInUser();
  let resultPromise = DataService.playRoulette(stringBets, user.id);
  let result = await resultPromise;
  console.log(result);
  response = result;
  if (result.success) {
    return result.winning_number;
  } else {
    // show error
  }
}

async function spin() {
  let winningSpin = await getWinningSpin(bet);
  let einsatz = 0;
  for (let y = 0; y < bet.length; y++) {
    einsatz += bet[y].amt;
  }
  document.getElementById("walletBalance").innerHTML =
    "Wallet: " + bankValue + " €";
  spinWheel(winningSpin);
  setTimeout(function () {
    if (numbersBet.includes(winningSpin)) {
      let winValue = 0;
      let betTotal = 0;
      for (let i = 0; i < bet.length; i++) {
        var numArray = bet[i].numbers.split(",").map(Number);
        if (numArray.includes(winningSpin)) {
          bankValue = bankValue + bet[i].odds * bet[i].amt + bet[i].amt;
          winValue = winValue + bet[i].odds * bet[i].amt;
          betTotal = betTotal + bet[i].amt;
        }
      }
      win(winningSpin, winValue, betTotal);
    } else {
      loose();
    }

    currentBet = 0;
    document.getElementById("bankSpan").innerText =
      "" + bankValue.toLocaleString("en-GB") + "";
    document.getElementById("betSpan").innerText =
      "" + currentBet.toLocaleString("en-GB") + "";

    let pnClass = numRed.includes(winningSpin)
      ? "pnRed"
      : winningSpin == 0
      ? "pnGreen"
      : "pnBlack";
    let pnContent = document.getElementById("pnContent");
    let pnSpan = document.createElement("span");
    pnSpan.setAttribute("class", pnClass);
    pnSpan.innerText = winningSpin;
    pnContent.append(pnSpan);
    pnContent.scrollLeft = pnContent.scrollWidth;

    bet = [];
    numbersBet = [];
    removeChips();
    wager = lastWager;
    if (bankValue == 0 && currentBet == 0) {
      gameOver();
    }
  }, 10000);
}

function win(winningSpin, winValue, betTotal) {
  if (winValue > 0) {
    document.getElementById("walletBalance").innerHTML =
      "Wallet: " + bankValue + " €";
    let notification = document.createElement("div");
    notification.setAttribute("id", "notification");
    let nSpan = document.createElement("div");
    nSpan.setAttribute("class", "nSpan");
    let nsnumber = document.createElement("span");
    nsnumber.setAttribute("class", "nsnumber");
    nsnumber.style.cssText = numRed.includes(winningSpin)
      ? "color:red"
      : "color:black";
    nsnumber.innerText = winningSpin;
    nSpan.append(nsnumber);
    let nsTxt = document.createElement("span");
    nsTxt.innerText = " Win";
    nSpan.append(nsTxt);
    let nsWin = document.createElement("div");
    nsWin.setAttribute("class", "nsWin");
    let nsWinBlock = document.createElement("div");
    nsWinBlock.setAttribute("class", "nsWinBlock");

    nSpan.append(nsWinBlock);
    nsWin.append(nsWinBlock);
    nsWinBlock = document.createElement("div");
    nsWinBlock.setAttribute("class", "nsWinBlock");

    nSpan.append(nsWinBlock);
    nsWin.append(nsWinBlock);
    nsWinBlock = document.createElement("div");
    nsWinBlock.setAttribute("class", "nsWinBlock");
    nsWinBlock.innerText =
      "Payout: " + (response.balance_after - response.balance_before);
    nsWin.append(nsWinBlock);
    nSpan.append(nsWin);
    notification.append(nSpan);
    container.prepend(notification);
    setTimeout(function () {
      notification.style.cssText = "opacity:0";
    }, 3000);
    setTimeout(function () {
      notification.remove();
    }, 8000);
  }
}

function loose() {}

function removeBet(e, n, t, o) {
  wager = wager == 0 ? 100 : wager;
  for (let i = 0; i < bet.length; i++) {
    if (bet[i].numbers == n && bet[i].type == t) {
      if (bet[i].amt != 0) {
        wager = bet[i].amt > wager ? wager : bet[i].amt;
        bet[i].amt = bet[i].amt - wager;
        bankValue = bankValue + wager;
        currentBet = currentBet - wager;
        document.getElementById("bankSpan").innerText =
          "" + bankValue.toLocaleString("en-GB") + "";
        document.getElementById("betSpan").innerText =
          "" + currentBet.toLocaleString("en-GB") + "";
        if (bet[i].amt == 0) {
          e.querySelector(".chip").style.cssText = "display:none";
        } else {
          let chipColour =
            bet[i].amt < 5
              ? "red"
              : bet[i].amt < 10
              ? "blue"
              : bet[i].amt < 100
              ? "orange"
              : "gold";
          e.querySelector(".chip").setAttribute("class", "chip " + chipColour);
          let chipSpan = e.querySelector(".chipSpan");
          chipSpan.innerText = bet[i].amt;
        }
      }
    }
  }

  if (currentBet == 0 && container.querySelector(".spinBtn")) {
    document.getElementsByClassName("spinBtn")[0].remove();
  }
}

function spinWheel(winningSpin) {
  for (let i = 0; i < wheelnumbersAC.length; i++) {
    if (wheelnumbersAC[i] == winningSpin) {
      var degree = i * 9.73 + 362;
    }
  }
  wheel.style.cssText = "animation: wheelRotate 5s linear infinite;";
  ballTrack.style.cssText = "animation: ballRotate 1s linear infinite;";
  let style = document.createElement("style");
  setTimeout(function () {
    ballTrack.style.cssText = "animation: ballRotate 2s linear infinite;";

    style.type = "text/css";
    style.innerText =
      "@keyframes ballStop {from {transform: rotate(0deg);}to{transform: rotate(-" +
      degree +
      "deg);}}";
    document.head.appendChild(style);
  }, 2000);
  setTimeout(function () {
    ballTrack.style.cssText = "animation: ballStop 3s linear;";
  }, 6000);
  setTimeout(function () {
    ballTrack.style.cssText = "transform: rotate(-" + degree + "deg);";
  }, 9000);
  setTimeout(function () {
    wheel.style.cssText = "";
    style.remove();
  }, 10000);
}

function removeChips() {
  var chips = document.getElementsByClassName("chip");
  if (chips.length > 0) {
    for (let i = 0; i < chips.length; i++) {
      chips[i].remove();
    }
    removeChips();
  }
}
</script>

<style>
*,
*::before,
*::after {
  box-sizing: content-box;
}

#containerDiv {
  cursor: pointer;
  display: flex;
  justify-content: center;
  background-color: #0c2d1c;
  opacity: 0.9;
  height: 500px;
  padding: 40px;
  color: #fff;
  margin-top: 100px;
}

#notification {
  position: absolute;
  width: 500px;
  height: 380px;
  background-color: #28a745;
  z-index: 10;
  transition: 1s;
  opacity: 1;
}

.nSpan {
  display: block;
  position: relative;
  top: 105px;
  font-size: 53px;
  text-align: center;
}

.nBtn {
  display: block;
  position: relative;
  top: 116px;
  left: 25%;
  width: 45%;
  padding: 10px;
  font-size: 32px;
  text-align: center;
  background-color: green;
  border-radius: 15px;
  box-shadow: 3px 4px rgb(0 0 0 / 25%);
  cursor: pointer;
  transition: 0.5s;
}

.nBtn:active {
  top: 118px;
  left: calc(25% + 2px);
  box-shadow: 1px 2px rgb(0 0 0 / 25%);
}

.nsWin {
  position: absolute;
  left: 4px;
}

.nsWinBlock {
  display: inline-block;
  font-size: 21px;
  border-right: 1px solid;
  border-left: 1px solid;
  padding-left: 40px;
  padding-right: 40px;
}

#betting_board {
  width: 500px;
  height: 315px;
  border: 1px solid;
}

.bbtop {
  margin-left: 2px;
}

.bbtoptwo {
  width: calc(50% - 8px);
  border: 1px solid;
  display: inline-block;
  padding: 12px 4px;
  margin-left: -2px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.number_board {
  margin-left: 1px;
}

.number_block {
  width: 39.94px;
  border: 1px solid;
  text-align: center;
  display: inline-block;
  margin: 0 -0.2px;
  padding: 32px 0px;
}

.nbn {
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 2px;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.bo3_board,
.oto_board {
  width: 500px;
  margin-left: 1px;
}

.bo3_block,
.oto_block {
  border: 1px solid;
  display: inline-block;
  padding: 8px 5px;
  width: 156.65px;
  margin-left: -1.98px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
}

.oto_block {
  width: 114.94px;
  margin-left: -1.98px;
}

.number_0 {
  position: absolute;
  border: 2px solid;
  margin-left: -57px;
  margin-top: -0.516px;
  padding: 120.55px 20px;
  border-bottom-left-radius: 100%;
  border-top-left-radius: 100%;
}

.tt1_block {
  border: 2px solid;
  width: 65px;
  position: absolute;
  margin-left: 498px;
  padding: 31.65px 0px;
  margin-top: -89.75px;
}

.winning_lines {
  position: absolute;
  margin-top: -137px;
  width: 603px;
  height: 0;
}

.wlttb {
  position: relative;
  width: 100%;
  height: 10px;
  margin-left: -7.75px;
  top: 269px;
}

#wlttb_top {
  position: absolute;
  margin-top: -89px;
  margin-left: 11px;
}

#wlttb_2,
#wlttb_3 {
  margin-top: 79px;
}

.wlrtl {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 266px;
  top: -3px;
  margin-left: 37px;
}

#wlrtl_2,
#wlrtl_3,
#wlrtl_6,
#wlrtl_9,
#wlrtl_11 {
  margin-left: 31px;
}
#wlrtl_4,
#wlrtl_5,
#wlrtl_7,
#wlrtl_8,
#wlrtl_10 {
  margin-left: 32px;
}

.wlcb {
  position: absolute;
  width: 477px;
  height: 41px;
  top: 255px;
  margin-left: 13px;
}

#wlcb_2 {
  top: 343px;
}

.cbbb {
  width: 20px;
  height: 20px;
  position: relative;
  display: inline-block;
  margin-left: 22px;
  margin-top: 9.5px;
  cursor: pointer;
}

#cbbb_1,
#cbbb_4,
#cbbb_5,
#cbbb_7,
#cbbb_9,
#cbbb_11,
#cbbb_12,
#cbbb_15,
#cbbb_16,
#cbbb_18,
#cbbb_20,
#cbbb_22 {
  margin-left: 21px;
}

#cbbb_3,
#cbbb_14 {
  margin-left: 20.5px;
}

#cbbb_6,
#cbbb_17 {
  margin-left: 23px;
}

#cbbb_8,
#cbbb_10,
#cbbb_19,
#cbbb_21 {
  margin-left: 22px;
}

.ttbbetblock {
  width: 19.1px;
  height: 10px;
  position: relative;
  display: inline-block;
  margin-left: 22.52px;
  top: -2px;
  cursor: pointer;
  z-index: 3;
}

.rtlbb1,
.rtlbb2,
.rtlbb3 {
  height: 45px;
  margin-top: 23px;
  width: 10px;
}

.rtlbb2,
.rtlbb3 {
  margin-top: 42px;
}

.spinBtn {
  position: absolute;
  margin-top: 357px;
  margin-left: -392px;
  font-size: 15px;
  cursor: pointer;
  background-color: #ffec00;
  padding: 10px 5px;
  border-radius: 100%;
  color: #000;
  font-weight: bold;
}

.redNum {
  background-color: #e0080b;
}

.blackNum {
  background-color: #000;
}

.chipDeck,
.bankContainer {
  position: absolute;
  margin-left: -383px;
  margin-top: -94px;
  width: 310px;
  height: 39px;
  padding: 12px 0;
  border: 1px solid;
  border-radius: 4px;
  box-shadow: inset -2px -4px rgb(0 0 0 / 20%);
}

.bankContainer {
  margin-top: -21px;
}

.cdChip {
  width: 27px;
  height: 27px;
  background-color: #fff;
  border: 5px solid;
  border-radius: 100%;
  display: inline-block;
  margin-left: 9px;
  color: #000;
  cursor: pointer;
}

.cdChipSpan {
  display: inherit;
  position: relative;
  top: 1px;
  width: 26px;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.cdChipActive {
  position: relative;
  top: -4px;
  box-shadow: 0 4px rgb(0 0 0 / 20%);
}

.clearBet {
  border-color: red;
  background-color: red;
  color: #fff;
  margin-left: 22px;
}

.clearBet .cdChipSpan {
  margin-left: -2px;
}

.bank,
.bet {
  display: inline-block;
  width: calc(50% - 23px);
  background-color: #000;
  font-size: 18px;
  margin-left: 7px;
  padding: 2px;
  border: 4px solid silver;
  border-radius: 7px;
  text-align: right;
}

.pnBlock {
  position: absolute;
  width: 500px;
  height: 45px;
  margin-top: 10px;
  margin-left: -1px;
}

#pnContent {
  border: 4px solid #d5b714;
  width: 485px;
  height: 38px;
  position: relative;
  display: block;
  margin-left: 4px;
  margin-top: 3px;
  background-color: #fff;
  color: #000;
  overflow: hidden;
}

.pnRed,
.pnBlack,
.pnGreen {
  position: relative;
  display: inline;
  padding: 10px;
  font-size: 19px;
  top: 5px;
}
.pnRed {
  color: red;
}
.pnBlack {
  color: black;
}
.pnGreen {
  color: green;
}

@keyframes wheelRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes ballRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}

.wheel {
  height: 312px;
  width: 312px;
  position: relative;
  left: -75px;
}

.ballTrack {
  width: 212px;
  height: 207px;
  position: absolute;
  left: 47.5px;
  top: 50px;
  border-radius: 100%;
  z-index: 2;
}

.ball {
  background-color: #fff;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  position: relative;
  left: 109px;
  top: -8px;
}

.turret {
  border-radius: 100%;
  background: radial-gradient(circle at 30% 30%, #f3c620, #1a1608);
  width: 45px;
  height: 45px;
  position: absolute;
  top: 133px;
  left: 134px;
  z-index: 1;
}

.turretHandle {
  background: radial-gradient(circle at 44%, #f3c620, #6a570f);
  width: 87px;
  height: 14px;
  position: absolute;
  left: 113px;
  top: 148px;
}

.thendOne,
.thendTwo {
  border-radius: 100%;
  background: radial-gradient(circle at 30% 30%, #f3c620, #1a1608);
  width: 25px;
  height: 25px;
  position: absolute;
}

.thendOne {
  top: -6px;
  left: -20px;
}

.thendTwo {
  top: -6px;
  left: 83px;
}

.block {
  transition: all 1s;
  position: absolute;
  width: 290px;
  height: 290px;
  border-radius: 100%;
  clip: rect(0px, 145px, 290px, 0px);
}

.sect {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 290px;
  height: 290px;
  border-radius: 100%;
  clip: rect(0px, 290px, 290px, 145px);
}

.double,
.single {
  position: absolute;
  z-index: 1;
  color: #fff;
  font-size: 14px;
  transform: rotate(3deg);
}

.double {
  left: 148px;
  margin-top: 4.5px;
}

.single {
  left: 152px;
  margin-top: 4.5px;
}

#sect1 .block {
  background-color: #016d29;
  transform: rotate(9.73deg);
}

#sect2 .block,
#sect4 .block,
#sect6 .block,
#sect8 .block,
#sect10 .block,
#sect12 .block,
#sect14 .block,
#sect16 .block,
#sect18 .block,
#sect20 .block,
#sect22 .block,
#sect24 .block,
#sect26 .block,
#sect28 .block,
#sect30 .block,
#sect32 .block,
#sect34 .block,
#sect36 .block {
  background-color: #e0080b;
  transform: rotate(9.73deg);
}

#sect3 .block,
#sect5 .block,
#sect7 .block,
#sect9 .block,
#sect11 .block,
#sect13 .block,
#sect15 .block,
#sect17 .block,
#sect19 .block,
#sect21 .block,
#sect23 .block,
#sect25 .block,
#sect27 .block,
#sect29 .block,
#sect31 .block,
#sect33 .block,
#sect35 .block,
#sect37 .block {
  background-color: #000;
  transform: rotate(9.73deg);
}

#sect2 {
  transform: rotate(9.73deg);
}

#sect3 {
  transform: rotate(19.46deg);
}

#sect4 {
  transform: rotate(29.19deg);
}

#sect5 {
  transform: rotate(38.92deg);
}

#sect6 {
  transform: rotate(48.65deg);
}

#sect7 {
  transform: rotate(58.38deg);
}

#sect8 {
  transform: rotate(68.11deg);
}

#sect9 {
  transform: rotate(77.84deg);
}

#sect10 {
  transform: rotate(87.57deg);
}

#sect11 {
  transform: rotate(97.3deg);
}

#sect12 {
  transform: rotate(107.03deg);
}

#sect13 {
  transform: rotate(116.76deg);
}

#sect14 {
  transform: rotate(126.49deg);
}

#sect15 {
  transform: rotate(136.22deg);
}

#sect16 {
  transform: rotate(145.95deg);
}

#sect17 {
  transform: rotate(155.68deg);
}

#sect18 {
  transform: rotate(165.41deg);
}

#sect19 {
  transform: rotate(175.14deg);
}

#sect20 {
  transform: rotate(184.87deg);
}

#sect21 {
  transform: rotate(194.6deg);
}

#sect22 {
  transform: rotate(204.33deg);
}

#sect23 {
  transform: rotate(214.06deg);
}

#sect24 {
  transform: rotate(223.79deg);
}

#sect25 {
  transform: rotate(233.52deg);
}

#sect26 {
  transform: rotate(243.25deg);
}

#sect27 {
  transform: rotate(252.98deg);
}

#sect28 {
  transform: rotate(262.71deg);
}

#sect29 {
  transform: rotate(272.44deg);
}

#sect30 {
  transform: rotate(282.17deg);
}

#sect31 {
  transform: rotate(291.9deg);
}

#sect32 {
  transform: rotate(301.63deg);
}

#sect33 {
  transform: rotate(311.36deg);
}

#sect34 {
  transform: rotate(321.09deg);
}

#sect35 {
  transform: rotate(330.82deg);
}

#sect36 {
  transform: rotate(340.55deg);
}

#sect37 {
  transform: rotate(350.28deg);
}

.pockets {
  position: absolute;
  top: 37px;
  left: 37px;
  width: 235px;
  height: 235px;
  background-color: #000;
  border-radius: 100%;
  opacity: 0.5;
}

.pocketsRim {
  position: absolute;
  top: 34px;
  left: 34px;
  width: 235px;
  height: 235px;
  border-radius: 100%;
  border: 3px solid silver;
}

.cone {
  position: absolute;
  top: 62px;
  left: 61px;
  height: 180px;
  width: 180px;
  border: 3px solid #9f9a9a;
  border-radius: 100%;
  background: radial-gradient(circle at 100px 100px, #892c10, #000);
}
/*wheel end*/

/*chip*/
.chip {
  width: 21px;
  height: 21px;
  background-color: #fff;
  border: 3px solid;
  border-radius: 100%;
  position: absolute;
}

.chipSpan {
  color: #000;
  font-weight: bold;
  font-size: 11px;
  position: relative;
  display: block;
  text-align: center;
  top: 4px;
}

.gold {
  border-color: gold;
}

.red {
  border-color: red;
}

.orange {
  border-color: orange;
}

.blue {
  border-color: blue;
}

.tt1_block .chip {
  margin-left: 19px;
  margin-top: -24px;
}

.number_block .chip {
  margin-left: 7px;
  margin-top: -24px;
}

.wlrtl .chip {
  margin-left: -9px;
  margin-top: 9px;
}

.cbbb .chip {
  margin-left: -4px;
  margin-top: -5px;
}

.ttbbetblock .chip {
  margin-left: -7px;
  margin-top: -8px;
}

#wlttb_top .chip {
  margin-left: -5px;
  margin-top: -8px;
}

.outerRim {
  position: absolute;
  left: 0;
  top: 0;
  width: 290px;
  height: 290px;
  border-radius: 100%;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
  border: 10px solid #f3c620;
}

.bbtoptwo .chip {
  margin-left: 108px;
  margin-top: -25px;
}

.number_0 .chip {
  margin-left: -8px;
  margin-top: -22px;
}

.bo3_block .chip {
  margin-left: 65px;
  margin-top: -26px;
}

.oto_block .chip {
  margin-left: 45px;
  margin-top: -25px;
}
</style>
